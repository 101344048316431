<template>
  <div style="width: 100%; height: 100%" class="fadeIn">
    <div class="title">
      <van-nav-bar
        :title="$route.query.pjName"
        left-text="返回"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div
      style="
        background: rgb(67, 93, 120);
        height: calc(100% - 46px);
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      "
    >
      <!-- <div class="header">
      <div style="">{{ $route.query.pjName }}</div>
      <div class="boxTitle">
   
        <div @click="$router.go(-1)">
          <img src="../assets/imgproject/close.jpg" alt="" />
        </div>
      </div>
    </div> -->
      <div :key="keys" class="realTimeTitle">
        <div>
          <div class="itemTitle">本小时能耗</div>
          <div class="itemBody">{{ ECdata["D400"] }}</div>
        </div>
        <div style="background: linear-gradient(#1391e5, #651ee7)">
          <div class="itemTitle">本日能耗</div>
          <div class="itemBody">{{ ECdata["D404"] }}</div>
        </div>
        <div style="background: linear-gradient(#6050d5, #7348d6)">
          <div class="itemTitle">本周能耗</div>
          <div class="itemBody">{{ ECdata["D445"] }}</div>
        </div>
        <div style="background: linear-gradient(#7c43c3, #7724b1)">
          <div class="itemTitle">本月能耗</div>
          <div class="itemBody">{{ ECdata["D408"] }}</div>
        </div>
        <div style="background: linear-gradient(#7defff, #5794de)">
          <div class="itemTitle">上小时能耗</div>
          <div class="itemBody">{{ ECdata["D402"] }}</div>
        </div>
        <div style="background: linear-gradient(#3ec4d7, #1f76c9)">
          <div class="itemTitle">昨日能耗</div>
          <div class="itemBody">{{ ECdata["D406"] }}</div>
        </div>
        <div style="background: linear-gradient(#be6af7, #9b5ff9)">
          <div class="itemTitle">上周能耗</div>
          <div class="itemBody">{{ ECdata["D447"] }}</div>
        </div>
        <div style="background: linear-gradient(#8a59fb, #7a21de)">
          <div class="itemTitle">上月能耗</div>
          <div class="itemBody">{{ ECdata["D410"] }}</div>
        </div>
      </div>
      <div class="realTimeHistory">
        <div>
          <div class="historyTitle">
            <div>时能耗统计</div>
            <div>
              <el-date-picker
                size="mini"
                v-model="date.timeDate"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                @change="selectDayTags"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>
          <div id="timeHistory" class="history"></div>
        </div>
        <div>
          <div class="historyTitle">
            <div>日能耗统计</div>
            <div>
              <el-date-picker
                size="mini"
                v-model="date.dayDate"
                value-format="yyyy-MM"
                type="month"
                placeholder="选择日期"
                @change="selectMothTags"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>

          <div id="dayHistory" class="history"></div>
        </div>
        <div>
          <div class="historyTitle" style="margin-top: 10px">
            <div>月能耗统计</div>
            <div>
              <el-date-picker
                size="mini"
                value-format="yyyy"
                v-model="date.monthDate"
                type="year"
                placeholder="选择日期"
                @change="selectYearTags"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>

          <div id="monthHistory" class="history"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      date: {
        timeDate: "",
        dayDate: "",
        monthDate: "",
      },
      keys: "%",
      ECdata: {},
      getFrom: {
        projectId: this.$route.query.projectId,
        paramTags: [
          "D400",
          "D402",
          "D404",
          "D406",
          "D408",
          "D410",
          "D445",
          "D447",
        ],
      },
      getBar(data, XData, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            y: 50,
            y2: 50,
            x: 50,
            x2: 50,
          },
          xAxis: {
            type: "category",
            data: XData,
            axisLabel: {
              inside: false,
              color: "#fff",
            },
          },
          yAxis: {
            type: "value",
            splitLine: false,
            axisLine: {
              show: false,
            },
            axisLabel: {
              inside: false,
              color: "#fff",
            },
            splitLine: {
              lineStyle: {
                color: "#17e3ff",
                width: 0.4,
              },
            },
          },

          series: [
            {
              data: data,
              type: "bar",
              // label: {
              //   show: true,
              //   position: "top",
              //   color: "#fff",
              //   // rotate:-30,
              //   fontSize: 8,
              // },
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#17e3ff" },
                  { offset: 1, color: "#5f5dda" },
                ]),
              },
            },
          ],
        };

        var chartDom = document.getElementById(ref);
        var myChart = echarts.init(chartDom);
        myChart.setOption(option);
      },
      getMothHistory(data, XData, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },

          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          grid: {
            y: 20,
            y2: 20,
            x: 50,
            x2: 50,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: XData,
              axisLabel: {
                inside: false,
                color: "#fff",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                inside: false,
                color: "#fff",
              },
              splitLine: {
                lineStyle: {
                  color: "#17e3ff",
                  width: 0.4,
                },
              },
            },
          ],
          series: [
            {
              type: "line",
              stack: "Total",
              areaStyle: {},
              emphasis: {
                focus: "series",
              },
              data: data,
              itemStyle: {
                color: "rgb(156,102,248)",
              },
            },
          ],
        };

        var chartDom = document.getElementById(ref);
        var myChart = echarts.init(chartDom);
        myChart.setOption(option);
      },
    };
  },
  methods: {
    onConfirm(value) {
      that.date.timeDate = value;
    },
    // 获取实时数据
    getParam() {
      that.$api.energy.getParams(that.getFrom).then((res) => {
        if (res.code == 200) {
          res.data.forEach((item) => {
            that.ECdata[item.paramTag] = item.value;
            that.keys = that.keys + "%";
          });
        }
      });
    },
    //  获取日能耗统计
    selectDayTags() {
      if (!that.date.timeDate) {
        let date = new Date();
        let full = date.getFullYear();
        let month =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        that.date.timeDate = full + "-" + month + "-" + Day;
      }

      let from = {
        paramTags: ["D400"],
        projectId: that.getFrom.projectId,
        date: that.date.timeDate,
        historyType: 2,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let data = [];
          let xData = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
          ];
          let obj = {};
          res.data.data[0].stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          xData.forEach((item) => {
            let num = obj[item] ? obj[item] : 0;
            if (item == 24) {
              num = obj["0"] ? obj["0"] : 0;
            }
            data.push(num);
          });
          that.getBar(data, xData, "timeHistory");
        }
      });
    },
    //  获取日能耗统计
    selectMothTags() {
      let date = new Date();
      let full = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      if (!that.date.dayDate) {
        that.date.dayDate = full + "-" + month;
      }

      let from = {
        paramTags: ["D402"],
        projectId: that.getFrom.projectId,
        date: that.date.dayDate,
        historyType: 3,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let data = [];
          let obj = {};
          res.data.data[0]?.stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          let xDate = new Date(full, month, 0).getDate();
          let XData = [];
          for (let i = 1; i <= xDate; i++) {
            XData.push(`${i}`);
            let num = obj[i] ? obj[i] : 0;
            data.push(num);
          }
          that.getMothHistory(data, XData, "dayHistory");
        }
      });
    },
    //  获取月能耗统计
    selectYearTags() {
      let date = new Date();
      let full = date.getFullYear();
      if (!that.date.monthDate) {
        that.date.monthDate = full + "";
      }

      let from = {
        paramTags: ["D408"],
        projectId: that.getFrom.projectId,
        date: that.date.monthDate,
        historyType: 4,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        if (res.code == 200) {
          let data = [];
          let obj = {};
          res.data.data[0]?.stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          let XData = [];
          for (let i = 1; i <= 12; i++) {
            XData.push(`${i}`);
            let num = obj[i] ? obj[i] : 0;
            data.push(num);
          }
          that.getMothHistory(data, XData, "monthHistory");
        }
      });
    },
  },
  mounted() {
    that = this;
    that.getParam();
    that.selectDayTags();
    that.selectMothTags();
    that.selectYearTags();
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.realTimeTitle {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.realTimeHistory {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.realTimeTitle > div {
  width: 45%;
  height: 60px;
  color: #fff;
  background: linear-gradient(#3ec1f5, #0483e2);
  border-radius: 8px;
  margin: 10px 0;
  box-shadow: #666 2px 2px 2px 1px;
}
.itemTitle {
  font-size: 16px !important;
  height: 40%;
  align-items: center;
  text-align: center;
}
.itemBody {
  height: 60%;
  text-align: center;
  line-height: 30px;
  font-size: 16px !important;
}
.realTimeHistory {
  height: 55%;
}
.realTimeHistory > div {
  width: 100%;

  border-top: 1px solid #444;
}
.historyTitle {
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 10px 30px;
  font-size: 22px;
  width: 100%;
}
::v-deep .historyTitle .el-input__inner {
  background: transparent;
  color: #fff;
}
.history {
  width: 100%;
  height: 300px;
}
.header {
  width: 100%;
  display: flex;
  height: 40px !important;
  justify-content: space-between !important;
  align-items: center;
  padding-left: 25px;
  box-sizing: border-box;
  font-size: 25px;
  font-weight: bold;
  color: #fff;
}
.boxTitle {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 25px;
  padding-top: 10px;
}
.boxTitle > div {
  width: 28px;
  height: 28px;
  overflow: hidden;
  margin: 0 5px;
  border-radius: 10px;
  cursor: pointer;
}
.boxTitle img {
  width: 100%;
}
::v-deep .el-date-editor {
  width: 140px;
}
.title {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}
</style>
